@charset "UTF-8";
@import "ap_preaset";


/*********************
*** Home Calendar ***
*********************/
b {
  font-weight: 500;
}

.daterangeWrap .titleArea {
  display: flex;
  justify-content: space-between;
  border-bottom: toRem(1) solid $ap-text-gray-4;
  padding: toRem(16);
}

.daterangeWrap .titleArea .titleWrap {
  display: flex;
  align-items: center;
  gap: toRem(12);
  flex-wrap: wrap;
}

.daterangeWrap .titleArea .titleWrap .em {
  font-weight: 700;
  font-size: toRem(12);
  line-height: toRem(18);
  color: $ap-point-orange;
}

.daterangeWrap .titleArea .titleWrap .title {
  font-weight: 700;
  font-size: toRem(16);
  line-height: toRem(22);
  color: $ap-text-dark;
}

.reservationCal .daterangeWrap {
  padding: 0;
}

.reservationCal .daterangeWrap .calendarWrap {
  margin: toRem(30) toRem(30) 0;
  gap: toRem(12);
}

.reservationCal .daterangeWrap .bottomArea {
  padding: 0 toRem(28) toRem(28);
  background-color: $ap-white;

  .taskButton.small {
    max-width: fit-content;
    padding: 0 20px;
  }
}

.reservationCal .toggleBtn:before, .reservationCal .toggleBtn:after {
  display: none;
}

.daterangeWrap .bottomArea .toggleWrap {
  .toggleBtn {
    width: toRem(19);
    height: toRem(19);
    position: absolute;
    top: toRem(4);
    left: 0;
    background-color: $ap-white;
    border: solid toRem(1) $ap-line-gray;
    border-radius: toRem(25);
    box-shadow: toRem(2) toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.06);
  }

  &.on .toggleBtn {
    left: 1.5rem;
  }
}

.pt-6{
  padding-top:6px;
}

.btnFix {
  &.left {
    left: toRem(0);
  }

  &.right {
    right: 0px;
  }
}
.reservationCal {
  .daterangeWrap {
    padding: 0;

    .calendarWrap {
      margin: toRem(28) toRem(24);
      gap: toRem(16);

      .calendar {
        .calendarButtons {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
        }
      }
      .btnFix {
        &.left {
          left: toRem(0);
        }

        &.right {
          right: 0px;
        }
      }
    }

    .priceUnit {
      padding: 0 toRem(30) 0 toRem(6);
      color: $ap-text-gray-1;
      text-align: right;
      font-size: toRem(12);
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      letter-spacing: toRem(-0.2);
    }

    .priceWrap {
      padding: toRem(24) toRem(24) toRem(20);
      margin-bottom: 0;
    }

    .bottomArea {
      padding: 0 toRem(24) toRem(24);
      background-color: $ap-white;
    }

    .caption {
      font-size: toRem(13);
      color: $ap-text-black;
      line-height: 1.2;
      text-align: right;
      padding-top: toRem(3);
    }
  }
}


.reservationCal .daterangeWrap .caption .price {
  font-size: inherit;
  font-weight: inherit;
}

.reservationCal .toggleBtn:before, .reservationCal .toggleBtn:after {
  display: none;
}

.floatingWrap .reservationCal .titleWrap .title:after {
  display: none;
}

.daterangeWrap .bottomArea .toggleBtn {
  width: toRem(19);
  height: toRem(19);
  position: absolute;
  top: toRem(4);
  left: 0;
  background-color: $ap-white;
  border: solid toRem(1) $ap-line-gray;
  border-radius: toRem(25);
  box-shadow: toRem(2) toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.06);
}

.floatingWrap .reservationCal .titleWrap {
  margin: 0;
}

.mobileHeader {
  display: none;

  .top {
    display: flex;
    justify-content: space-between;
    padding: toRem(16) toRem(20) toRem(16) toRem(24);

    div:first-child {
      color: $ap-text-black;
      font-size: toRem(16);
      font-weight: 700;
      letter-spacing: toRem(-0.32);
    }
  }

  // 상단 고정 요일
  .weekDays {
    display: none;

    ul {
      display: flex;
      padding: 0 toRem(16);
      border-bottom: solid toRem(0.5) #DDD;

      li {
        flex: 1;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.priceUnitWrapper {
  &.priceUnitTop {
    display: none;
  }

  &.priceUnitBottom {
    display: flex;
    justify-content: flex-end;
  }
}

@include rwd('lg') {
  .reservationCal {
    .daterangeWrap {
      justify-content: flex-start;

      .caption {
        text-align: center;
      }

      .topArea {
        padding-top: 0;
        padding-bottom: toRem(240);
      }

      .titleArea {
        border-bottom: none;
      }

      .priceWrap {
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: toRem(107);
        padding: 0 toRem(16);
        gap: toRem(10);
        height: toRem(110);
        z-index: 2;
        border: none;
        position: absolute;
        width: 100%;


        .left {
          gap: toRem(4);
          border-radius: toRem(12);
          padding: toRem(14);
          background: #F7F7F7;
          width: 100%;
          justify-content: space-between;
          font-weight: 400;

          .tripPrice {
            flex: 1;
          }

          .price {
            padding-bottom: 3px;
          }

          ul > li.sub {
            justify-content: center;
          }
        }

        .right {
          width: 100%;
          position: initial;
          padding-top: toRem(8);
        }
      }

      .price.ftO {
        flex-basis: inherit;
      }

      .right ul,
      .sub,
      .price {
        text-align: center;
      }

      .estimatedLowFare {
        justify-content: center;
      }

      .bottomArea {
        flex-direction: column;
        align-items: flex-start;
        padding: toRem(16);
        height: toRem(265);
        z-index: 1;

        .btnTwin {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: toRem(16);
        }

        .toggleWrap .container {
          margin-top: toRem(-5);
        }

        .taskButton.small {
          max-width: 100%;
        }
      }
    }
  }

  .mobileHeader {
    display: block;

    .top {
      padding: toRem(16) toRem(30) toRem(16) toRem(30);
    }
  }

}


@include rwd('sm') {
  .reservationCal {
    .daterangeWrap {
      .calendarWrap .list .weekDays {
        display: none;
      }

      .calendarWrap {
        margin: toRem(28) toRem(24);
        gap: toRem(16);
        .calendar{
          position: relative;
        }
      }

      .icon.arrow {
        width: 1rem;
        height: 1rem;
      }

      .priceWrap {
        .left .image .fltImg {
          padding: 0 toRem(4);
          width: toRem(28);
          background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_flight_without_dot.svg') no-repeat;
          background-position: center;
        }

        .left {
          gap: toRem(0);
          padding: toRem(14) toRem(4);
          justify-content: center;
        }

        .left label {
          font-size: toRem(12);
          font-weight: 400;
        }

        .left > ul > li.sub .date {
          font-size: toRem(13);
          font-weight: 500;
        }


        .right ul {
          padding-top: toRem(6);
          display: inherit;
          position: inherit;
        }
      }

      .priceUnit {
        padding: 0 0 0 toRem(6);
      }
    }

    .bottomArea {
      padding: toRem(16);

      .content {
        margin-top: toRem(2);
      }
    }

    .mobileHeader {
      .top {
        padding: toRem(16) toRem(20) toRem(16) toRem(24);
      }

      .weekDays {
        display: block;
      }
    }


    .priceUnitWrapper {
      padding: toRem(10) toRem(16);

      &.priceUnitTop {
        display: flex;
        justify-content: flex-start;
      }

      &.priceUnitBottom {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 359px) {
  .reservationCal .toggleWrap.flex {
    text-align: left;
    margin-top: 0;
  }
  .reservationCal .daterangeWrap .priceWrap .left > ul > li.sub {
    flex-wrap: wrap;
    justify-content: center;
  }
  .reservationCal .toggleWrap.flex {
    margin-top: 0;
  }
  .reservationCal .daterangeWrap .priceWrap .right ul,
  .reservationCal .daterangeWrap .priceWrap .sub,
  .reservationCal .daterangeWrap .priceWrap .price {
    font-size: 0.75rem;
    line-height: 1.5;
  }
}

.slctBox > .slctCon > li > span span.icon.newBadge {
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_new_badge.svg');
  width: 32px;
  height: 16px;
  margin-left: 4px;
}

/*****************************
*** Old Version Calendarr ***
*****************************/

.daterangeWrap .calendarWrap .calendar .list > ul > li {
  a {
    height: 100%;
    display: block;
    color: $ap-text-black;
    line-height: 1.2em;

    &:hover {
      color: $ap-solid-orange;
    }
  }

  &.disable {
    a {
      cursor: not-allowed;
      color: $ap-text-gray-3;
    }
  }
}

.daterangeWrap .calendarWrap .calendar .list > ul > li span {
  color: $ap-solid-skyblue;
  font-size: toRem(14);
  font-weight: 400;
  letter-spacing: toRem(-1);
}

.daterangeWrap .calendarWrap .calendar .list > ul > li.oneDay a,
.daterangeWrap .calendarWrap .calendar .list > ul > li.startDay a,
.daterangeWrap .calendarWrap .calendar .list > ul > li.on a,
.daterangeWrap .calendarWrap .calendar .list > ul > li.endDay a {
  color: $ap-white;
}

@include rwd('xs') {
  .daterangeWrap .calendarWrap .calendar .list > ul > li span {
    font-size: 0.75rem;
  }
}

@include rwd('lg') {
  .fltPoint {
    .right ul {
      padding-top: toRem(6);
      display: flex;
      position: inherit;
    }
  }
}
